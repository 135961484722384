
import React from "react"
import PropTypes from "prop-types"

import { Link, graphql, useStaticQuery } from 'gatsby'

const CategoryService = () => {
    const url = typeof window !== 'undefined' ? window.location.href.split("/")[4] : '';
    const data = useStaticQuery(graphql`
  query  {
    allServiceItemJson(filter: {ServiceItemCTALink: {ne: null}}) {
        nodes {
         
            ServiceItemCTALink
            ServiceItemTitle
         
        }
      }
  }
  `)


    return (
        <>

            {data.allServiceItemJson.nodes.map((node, index) => {
                return (
                    <div key={index}>
                        <small>
                            <Link className={url === node.ServiceItemCTALink ? "active-link" : "text-dark"} to={"/services/" + node.ServiceItemCTALink}>
                                {node.ServiceItemTitle}
                            </Link>
                        </small>
                        <br></br>
                    </div>
                )
            })}

        </>)
}



export default CategoryService

