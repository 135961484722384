import React, { useState, useCallback } from 'react';
import Layout from "../retech/components/layout/Layout"
import { Card, Button, Form, InputGroup } from "react-bootstrap"
import { Link, graphql } from "gatsby"
import CategoryService from "../retech/components/Service/servicecategory"
import { Helmet } from "react-helmet"
import Recaptcha from 'react-google-recaptcha'
import { useDropzone } from 'react-dropzone'

import { navigate } from 'gatsby'



export default function Job({ data }) {
    const url = typeof window !== "undefined" ? window.location.href : ""
    const urlimage = typeof window !== "undefined" ? window.location.hostname : ""
    const [buttonDisabled, setButtonDisabled] = React.useState(true)
    const [resume, setResume] = useState({});
    const [isLoading, setLoading] = React.useState(false)
    const source = typeof window !== "undefined" ? window.location.href.split("/")[3] : ""

    /*  const onDrop = useCallback(acceptedFiles => {
         console.log(acceptedFiles)
         setFile(acceptedFiles[0])
     }, [])
     const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop }) */
    const url_string = typeof window !== "undefined" ? new URLSearchParams(window.location.search).get('opportunityTitle') : "";




    const [formState, setFormState] = useState({
        source: source,
        firstname: "",
        lastname: "",
        phone: "",
        email: "",
        priority: "",
        message: "",
        job: url_string,
    })

    const encodddde = (data) => {
        return Object.keys(data)
            .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
            .join("&");
    }
    const handleChange = e => {
        setFormState({
            ...formState,
            [e.target.name]: e.target.value

        })
    }

    const encode = (data) => {
        const formData = new FormData();
        Object.keys(data).forEach((k) => {
            formData.append(k, data[k])
        });
        return formData
    }
    const handleSubmit = e => {
        let x = document.getElementById("firstname").value;
        let n = document.getElementById("job").value;
        let y = document.getElementById("lastname").value;
        let z = document.getElementById("phone").value;
        let f = document.getElementById("email").value;


        if (x == "" || n == "" || y == "" || z == "" || f == "") {

            e.preventDefault();
            return false;

        };
        setLoading(true);
        const form = e.target
        const recaptchaValue = recaptchaRef.current.getValue()
        const data = { "form-name": "jobs", ...formState, 'g-recaptcha-response': recaptchaValue, resume }
        fetch("/", {
            method: "POST",
            // headers: { "Content-Type": 'multipart/form-data; boundary=random' },
            body: encode(data)
        }).then(() => { navigate(form.getAttribute('action')); setLoading(true) })
            .catch(error => alert(error));
        e.preventDefault();
    };
    const recaptchaRef = React.createRef()

    var callback = function () {
        console.log('Done!!!!');
    };

    // specifying verify callback function
    var verifyCallback = function (response) {
        console.log(response);
    };
    return (
        <Layout>
            <Helmet>
                <title>Retech | Jobs</title>

                <meta name="description" content="Jobs" />

                <meta property="og:type" content="website" />
                <meta property="og:url" content={url} />
                <meta property="og:title" content="Jobs" />
                <meta
                    property="og:description"
                    content="Jobs"
                />
                <meta property="og:image" content={urlimage + "/logo.svg"} />
                <meta property="twitter:card" content="summary_large_image" />
                <meta property="twitter:url" content={url} />
                <meta property="twitter:title" content="Jobs" />
                <meta
                    property="twitter:description"
                    content="Jobs"
                />
                <meta property="twitter:image" content={urlimage + "/logo.svg"} />
            </Helmet>




            <div className="container form-product">
                <div className="col-md-12 px-0 py-5">
                    <Card className="product color-retech-card">
                        <h4 className="text-white font-weight-bolder mb-3">
                            Apply at Retech
                        </h4>
                        <p className="text-white">
                            <small>
                                Fill up the form our team will get back to you within 24 Hours
                            </small>
                        </p>
                        <form
                            onSubmit={handleSubmit}
                            name="jobs"
                            method="post"
                            data-netlify="true"
                            data-netlify-recaptcha="true"
                            data-netlify-honeypot="bot-field"
                            action="/thank-you"
                        >
                            <input type="hidden" name="form-name" value="jobs" />

                            <Form.Row className="mb-4">
                                <div className="col-md-3 mb-4">
                                    <Form.Control
                                        className="py-4"
                                        type="text"

                                        id="source"
                                        name="source"
                                        onChange={handleChange}
                                        value={formState.source}
                                        style={{ display: "none" }}
                                    />
                                    <Form.Label className="font-wight-bolder text-white">
                                        First Name*
                                    </Form.Label>
                                    <Form.Control
                                        className="py-4"
                                        placeholder="First Name"
                                        id="firstname"
                                        type="text"
                                        name="firstname"
                                        onChange={handleChange}
                                        value={formState.firstname}
                                    />
                                </div>
                                <div className="col-md-3 mb-4">
                                    <Form.Label className="font-wight-bolder text-white">
                                        Last Name*
                                    </Form.Label>
                                    <Form.Control
                                        className="py-4"
                                        placeholder="Enter your last name"
                                        id="lastname"
                                        type="text"
                                        name="lastname"
                                        onChange={handleChange}
                                        value={formState.lastname}

                                    />
                                </div>
                                <div className="col-md-6 mb-4">
                                    <Form.Label className="font-wight-bolder text-white">
                                        Phone*
                                    </Form.Label>
                                    <Form.Control
                                        className="py-4"
                                        type="tel"
                                        placeholder="Enter your phone"
                                        id="phone"
                                        name="phone"
                                        onChange={handleChange}
                                        value={formState.phone}

                                    />
                                </div>
                                <div className="col-md-6 mb-4">
                                    <Form.Label className="font-wight-bolder text-white">
                                        Email*
                                    </Form.Label>
                                    <Form.Control
                                        className="py-4"
                                        type="email"
                                        placeholder="Enter your email address"
                                        id="email"
                                        name="email"
                                        onChange={handleChange}
                                        value={formState.email}
                                    />
                                </div>

                                <div className="col-md-6 mb-4">
                                    <Form.Label className="font-wight-bolder text-white">
                                        Job Being Applied For*
                                    </Form.Label>
                                    <Form.Control
                                        className="py-4"
                                        type="text"

                                        id="job"
                                        name="job"
                                        onChange={handleChange}
                                        value={formState.job}

                                    />
                                </div>
                                <div className="col-md-6 mb-4">
                                    <Form.Label className="font-wight-bolder text-white">
                                        CV*
                                    </Form.Label>
                                    <input type="file" name="resume" id="resume" onChange={(e) => {
                                        setResume(e.target.files[0])
                                    }}
                                        value={formState.resume} />


                                </div>

                            </Form.Row>

                            <Recaptcha
                                ref={recaptchaRef}
                                sitekey="6LdwPXYbAAAAAMgj5Nqj76lv39oKQB5Jtj48_9N9"
                                size="normal"
                                id="recaptcha-google"
                                onChange={() => setButtonDisabled(false)}
                            />
                            <InputGroup className="my-3">
                                <InputGroup.Append>
                                    {!isLoading && <Button
                                        type="submit"
                                        variant="outline-secondary"
                                        className="text-white border-white p-3"
                                        disabled={buttonDisabled}
                                    >
                                        Send Message
                                    </Button>
                                    }
                                    {isLoading && <Button
                                        type="submit"
                                        variant="outline-secondary"
                                        className="text-white border-white p-3"
                                        disabled={buttonDisabled}
                                    ><i className="fa fa-spinner fa-spin"></i>
                                        Sending...
                                    </Button>
                                    }
                                </InputGroup.Append>
                            </InputGroup>


                        </form>         </Card>
                </div>
            </div>
        </Layout>
    )
}

